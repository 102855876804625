.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-left: 8px;
  
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgba(var(--theme-color), 1);
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  .blog-content {
    width: 100% !important;
    padding-left: 30px;
    [dir="rtl"] & {
        padding-left: 0;
        padding-right: 25px;
    }
    @media (max-width: 991px) {
        width: 100% !important;
        padding-left: 0;
        padding-top: 25px;
        [dir="rtl"] & {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .main-title {
        font-size: 28px;
        color: rgba(var(--white), 1);
        line-height: 1.2;
        @media (max-width: 1300px) {
            font-size: 24px;
        }
        @media (max-width: 1199px) {
            font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    p {
        font-size: 20px;
        color: rgba(var(--content-color), 1);
        margin-top: 5px;
        @media (max-width: 1300px) {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    .btn-arrow {
        margin-top: 35px;
        @media (max-width: 1300px) {
            margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 15px;
        gap: 8px 0;
        li {
            color: rgba(var(--content-color), 1);
            padding-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            padding-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            .iconsax {
                svg [stroke] {
                    stroke: rgba(var(--content-color), 1);
                }
            }
            .avtar {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                object-fit: cover;
            }
            + li {
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: rgba(var(--content-color), 1);
                    height: 70%;
                    width: 1px;
                    [dir="rtl"] & {
                        left: unset;
                        right: 0;
                    }
                    @media (max-width: 576px) {
                        display: none;
                    }
                }
            }
        }
    }
}  
.login-section {
    
    background-size: cover;
    height: 100%;
    padding-left: calc(40px + (160 - 40) * ((100vw - 991px) / (1920 - 991)));
    padding-right: calc(40px + (160 - 40) * ((100vw - 991px) / (1920 - 991)));
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 60px;
    padding-top: 52px;
}

@media (max-width: 991px) {
    .login-section {
        padding-left: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding-right: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
        height: auto;
        padding-bottom: 0;
        align-items: unset;
    }
}

.login-section .row {
    width: 100%;
}

.login-section .login-box {
    background: rgba(58, 237, 177, 0.05);
    border: 1px solid rgba(var(--title-color), 0.1);
    backdrop-filter: blur(6px);
    border-radius: 6px;
    padding: calc(30px + (50 - 30) * ((100vw - 991px) / (1920 - 991)));
    height: 100%;
    min-height: calc(100vh - 150px);
}

@media (max-width: 991px) {
    .login-section .login-box {
        height: auto;
        min-height: unset;
        padding: 30px 20px;
        margin-top: calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320)));
    }
}

.login-section .login-box h2 {
    font-size: calc(26px + (30 - 26) * ((100vw - 991px) / (1920 - 991)));
    font-weight: 500;
    color: rgba(var(--white), 1);
    margin-bottom: calc(15px + (35 - 15) * ((100vw - 991px) / (1920 - 991)));
    text-align: center;
    margin-top: calc(-3px + (-6 - -3) * ((100vw - 320px) / (1920 - 320)));
}

@media (max-width: 991px) {
    .login-section .login-box h2 {
        font-size: calc(22px + (26 - 22) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    }
}

.login-section .login-box h2 span {
    color: rgba(var(--theme-color), 1);
}

.login-section .login-box .nav-tabs {
    border-bottom: 2px solid rgba(var(--theme-color), 0.1);
    margin-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.login-section .login-box .nav-tabs .nav-link {
    color: rgba(var(--content-color), 1) !important;
    width: 100%;
    background-color: transparent !important;
    border: none;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
    position: relative;
    outline: none;
}

.login-section .login-box .nav-tabs .nav-link.active {
    color: rgba(var(--theme-color), 1) !important;
}

.login-section .login-box .nav-tabs .nav-link.active:after {
    content: "";
    position: absolute;
    border-bottom: 2px solid rgba(var(--theme-color), 1);
    width: 100%;
    left: 0;
    bottom: 0;
}

.login-section .login-box .nav-tabs .nav-item {
    width: 50%;
    text-align: center;
}

.login-section .social-btn {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.login-section .social-btn li {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(var(--white), 0.8);
    border-radius: 8px;
}

.login-section .social-btn li a {
    display: flex;
    align-items: center;
    gap: 10px;
    height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--white), 1);
    width: 100%;
    justify-content: center;
}

.login-section .btn-absolute {
    position: absolute;
    left: 50px;
    bottom: 50px;
    width: calc(100% - 100px);
}

@media (max-width: 991px) {
    .login-section .btn-absolute {
        position: relative;
        left: unset;
        bottom: unset;
        width: 100%;
    }
}

/*=====================
    2.3 Button CSS start
==========================*/

.btn {
    font-size: 22px;
    color: rgba(var(--theme-color), 1);
    font-weight: 500;
}

.btn-theme {
    position: relative;
    display: flex;
    text-align: center;
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    background: rgba(var(--theme-color), 0.21);
    backdrop-filter: blur(6px);
    border-radius: 8px;
    padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.btn-theme span {
    margin: auto;
    color: rgba(var(--theme-color), 1);
}

.btn-theme:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    background: linear-gradient(114.72deg, rgba(62, 255, 255, 0.5) -16.82%, rgba(58, 237, 177, 0.5) 59.74%);
  /*  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);*/
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border: 1px solid;
    border-image-source: linear-gradient(114.72deg, rgba(62, 255, 255, 0.5) -16.82%, rgba(58, 237, 177, 0.5) 59.74%);
}

.btn-theme:hover {
    background: rgba(var(--theme-color), 0.21);
    box-shadow: 0 0 17px 3px rgba(52, 208, 156, 0.34);
}

.btn-arrow {
    color: rgba(var(--theme-color), 1);
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 18px;
    width: max-content;
}

.btn-arrow .icon-arrow {
    width: calc(36px + (42 - 36) * ((100vw - 320px) / (1920 - 320)));
    height: calc(36px + (42 - 36) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--theme-color), 1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #0f141d;
    position: relative;
    transition: all 0.5s ease;
}

.btn-arrow .icon-arrow:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--theme-color), 0.15);
    border-radius: 100%;
    right: -8px;
    transition: all 0.5s ease;
}

.btn-arrow .icon-arrow svg {
    stroke: rgba(var(--dark-title), 1);
    fill: rgba(var(--dark-title), 1);
    transform: rotate(45deg);
    width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    transition: all 0.5s ease;
}

.btn-arrow:hover .icon-arrow {
    transform: translateX(5px);
}

.btn-arrow:hover .icon-arrow:after {
    right: 0;
}

.btn-arrow:hover .icon-arrow svg {
    transform: rotate(90deg);
}

.btn-solid {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--dark-title), 1);
    background-color: rgba(var(--theme-color), 1);
    border: 1px solid rgba(var(--theme-color), 1);
    display: inline-block !important;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    width: max-content !important;
    transition: all 0.5s ease !important;
}

.btn-solid:hover {
    background-color: transparent;
    color: rgba(var(--theme-color), 1);
}
.login-animation {
    height: 100%;
    position: relative;
}

@media (max-width: 991px) {
    .login-animation {
        height: calc(360px + (500 - 360) * ((100vw - 320px) / (1920 - 320)));
        transform: scale(0.6);
        transform-origin: bottom;
        margin-bottom: 40px;
    }
}

.login-animation .img-base {
    position: absolute;
    bottom: 0;
}

.login-animation .img-light {
    position: absolute;
    bottom: calc(60px + (90 - 60) * ((100vw - 991px) / (1920 - 991)));
    mix-blend-mode: color-dodge;
}

@media (max-width: 991px) {
    .login-animation .img-light {
        bottom: 60px;
    }
}

.login-animation .img-face {
    position: absolute;
    bottom: calc(30px + (80 - 30) * ((100vw - 991px) / (1920 - 991)));
    left: calc(30px + (90 - 30) * ((100vw - 991px) / (1920 - 991)));
}

[dir="rtl"] .login-animation .img-face {
    left: unset;
    right: calc(30px + (90 - 30) * ((100vw - 991px) / (1920 - 991)));
}

@media (max-width: 991px) {
    .login-animation .img-face {
        bottom: 30px;
        left: 120px;
    }

    [dir="rtl"] .login-animation .img-face {
        left: unset;
        right: 120px;
    }
}

.login-animation .img-face img {
    animation: flip 3s linear infinite alternate;
    mix-blend-mode: color-dodge;
}

.logo-auth {
    position: absolute;
    top: calc(40px + (60 - 40) * ((100vw - 991px) / (1920 - 991)));
    left: calc(40px + (160 - 40) * ((100vw - 991px) / (1920 - 991)));
    z-index: 1;
}

[dir="rtl"] .logo-auth {
    left: unset;
    right: calc(40px + (160 - 40) * ((100vw - 991px) / (1920 - 991)));
}

@media (max-width: 991px) {
    .logo-auth {
        top: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
        left: calc(15px + (60 - 15) * ((100vw - 320px) / (1920 - 320)));
    }

    [dir="rtl"] .logo-auth {
        left: unset;
        right: calc(15px + (60 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
}
/*=====================
    2.6 form CSS start
==========================*/
.input-group > .form-control:focus,
.input-group > .form-floating:focus,
.input-group > .form-select:focus,
.input-group > .form-control:focus-within,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus-within {
    box-shadow: none;
    z-index: unset;
}

.auth-form input[type="number"]::-webkit-inner-spin-button,
.auth-form input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.auth-form .form-label {
    color: rgba(var(--title-color), 1);
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.auth-form .form-control {
    background: rgba(var(--title-color), 0.1);
    border-radius: 4px;
    border: none;
    padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)))
        calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    box-shadow: none;
    outline: none;
    color: rgba(var(--content-color), 1);
}

.auth-form .form-group {
    position: relative;
}

.auth-form .form-group .form-control {
    padding-left: 70px;
}

.auth-form .form-group .iconsax {
    position: absolute;
    left: 14px;
    bottom: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid rgba(var(--content-color), 1);
    padding-right: 14px;
}

.auth-form .form-group .iconsax svg [stroke] {
    stroke: rgba(var(--content-color), 1);
}

.auth-form .form-group .iconsax svg [fill] {
    fill: rgba(var(--content-color), 1);
}

.divider {
    margin-top: 25px;
    text-align: center;
    position: relative;
}

.divider:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(var(--content-color), 1);
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.divider h3 {
    color: rgba(var(--content-color), 1);
    margin-bottom: 0;
    display: inline-block;
    background-color: #0e242f;
    padding: 0 12px;
}

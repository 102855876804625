.custom-modal-width .modal-dialog {
    max-width: 1200px; /* Set the desired width */
  }

  .modal{
  --bs-modal-zindex: 1055;
  --bs-modal-width: 800px;
 }

 .fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
}
.table-category-mere {
    background-color: #f8f9fa0e; /* Fond gris clair pour les catégories mères */
    font-weight: bold; /* Gras pour le texte des catégories mères */
}

.table-category-mere td {
    border-top: 2px solid #dee2e6; /* Bordure supérieure pour séparer les catégories mères */
}
.service-section {
    padding: 10px;
    background: linear-gradient(180.02deg, rgba(var(--title-color), 0.05) 0.02%, rgba(var(--title-color), 0) 90.86%);
    position: relative;
    overflow: hidden;
    width: 300px !important;
    @media (max-width: 991px) {
        padding: 10  !important;
    }
    &:after {
        content: "";
        position: absolute;
      
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.65;
        background-size: cover;
    }
    .service-box {
        margin: 10px;
        padding: 10px;
        background: #0a0e17;
        position: relative;
        border-radius: 4px;
        height: 100%;
        @media (max-width: 1400px) {
            padding: 30px;
        }
        @media (max-width: 1199px) {
            padding: 20px;
        }
        @media (max-width: 576px) {
            padding: 14px;
        }
        &:after {
            content: "";
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(
                315.14deg,
                rgba(var(--theme-color), 0) 65%,
                rgba(var(--theme-color), 0.15) 83.43%
            );
            border-radius: 4px;
            z-index: -1;
            transition: all 0.5s ease;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                148deg,
                rgba(var(--theme-color), 0.15) -15.73%,
                rgba(var(--theme-color), 0) 98.72%
            );
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease;
        }
        .service-icon {
            width: 72px;
            height: 72px;
            background: rgba(var(--title-color), 0.05);
            border-radius: 4px;
            position: relative;
            transition: all 0.5s ease;
            @media (max-width: 1400px) {
                width: 62px;
                height: 62px;
            }
            @media (max-width: 576px) {
                width: 52px;
                height: 52px;
            }
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            .outline-icon {
                transition: all 0.5s ease;
            }
            .bold-icon {
                opacity: 0;
                visibility: hidden;
                transform: translate(-50%, -50%) rotate(180deg);
                transition: all 0.5s ease;
            }
        }
        .service-content {
            margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
            h3 {
                color: rgba(var(--white), 1);
                text-transform: capitalize;
                display: flex;
                align-items: center;
                gap: 10px;
            }
            p {
                margin-bottom: 0;
            }
        }
       
    }
 
    .service-info {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 32px;
        @media (max-width: 1199px) {
            padding-left: 0;
        }
        p {
            margin-bottom: 0;
        }
        a {
            margin-top: 35px;
            @media (max-width: 991px) {
                margin-top: 25px;
            }
        }
    }
}

.fixed-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    border-bottom: 1px solid rgba(var(--title-color), 0.15);
    width: calc(100% - 100px);
    padding: 24px;
    z-index: 1;
    background-color: #141d2a;
height: 89px !important;
    @media (max-width: 767px) {
        width: 100%;
        height: 89px !important;
    }}